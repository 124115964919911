import revive_payload_client_SkLQG40B5U from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@capacitor+preferences@7.0.0_@capacitor+core@7.0.1__@parcel+watcher@2.5.1_@types+_myik5z7xagxrnruqhz5ibhl4fa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_oh1D6btsA3 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@capacitor+preferences@7.0.0_@capacitor+core@7.0.1__@parcel+watcher@2.5.1_@types+_myik5z7xagxrnruqhz5ibhl4fa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vlrZBwG8F0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@capacitor+preferences@7.0.0_@capacitor+core@7.0.1__@parcel+watcher@2.5.1_@types+_myik5z7xagxrnruqhz5ibhl4fa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_WhpaQUCzsK from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.2.2_magicast@0.3.5_rollup@4.34.6_webpack-sources@3.2.3/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_mUqXC6pCvf from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@capacitor+preferences@7.0.0_@capacitor+core@7.0.1__@parcel+watcher@2.5.1_@types+_myik5z7xagxrnruqhz5ibhl4fa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_17moaLUE1w from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@capacitor+preferences@7.0.0_@capacitor+core@7.0.1__@parcel+watcher@2.5.1_@types+_myik5z7xagxrnruqhz5ibhl4fa/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_1vgfTlv54l from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@capacitor+preferences@7.0.0_@capacitor+core@7.0.1__@parcel+watcher@2.5.1_@types+_myik5z7xagxrnruqhz5ibhl4fa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_skquAVxLbD from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@capacitor+preferences@7.0.0_@capacitor+core@7.0.1__@parcel+watcher@2.5.1_@types+_myik5z7xagxrnruqhz5ibhl4fa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_2Jx7v3LqKz from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_typescript@5.7.3_vue@3.5.3_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/web/.nuxt/components.plugin.mjs";
import prefetch_client_8rq7IBRl0k from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@capacitor+preferences@7.0.0_@capacitor+core@7.0.1__@parcel+watcher@2.5.1_@types+_myik5z7xagxrnruqhz5ibhl4fa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/vercel/path0/web/.nuxt/formkitPlugin.mjs";
import sentry_client_rCaV9InRPc from "/vercel/path0/packages/sentry/plugins/sentry.client.ts";
import mapbox_client_HMmMV6F7A6 from "/vercel/path0/web/plugins/mapbox.client.ts";
import vue_query_wrmMkNpEpe from "/vercel/path0/web/plugins/vue-query.ts";
export default [
  revive_payload_client_SkLQG40B5U,
  unhead_oh1D6btsA3,
  router_vlrZBwG8F0,
  supabase_client_WhpaQUCzsK,
  payload_client_mUqXC6pCvf,
  navigation_repaint_client_17moaLUE1w,
  check_outdated_build_client_1vgfTlv54l,
  chunk_reload_client_skquAVxLbD,
  plugin_vue3_2Jx7v3LqKz,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8rq7IBRl0k,
  formkitPlugin_pZqjah0RUG,
  sentry_client_rCaV9InRPc,
  mapbox_client_HMmMV6F7A6,
  vue_query_wrmMkNpEpe
]