import { default as explore_45courses3T2XhVpsZoMeta } from "/vercel/path0/web/pages/about/explore-courses.vue?macro=true";
import { default as index5WqLcFBFtGMeta } from "/vercel/path0/web/pages/about/index.vue?macro=true";
import { default as delete7cMwrJxPhCMeta } from "/vercel/path0/web/pages/account/delete.vue?macro=true";
import { default as indexvGMLrCzrS1Meta } from "/vercel/path0/web/pages/account/index.vue?macro=true";
import { default as reset_45passwordf9VzubbknJMeta } from "/vercel/path0/web/pages/account/reset-password.vue?macro=true";
import { default as course_45requestsP9y2ESxpaBMeta } from "/vercel/path0/web/pages/admin/course-requests.vue?macro=true";
import { default as _91areaSlug_935RHbCZS09xMeta } from "/vercel/path0/web/pages/areas/[areaSlug].vue?macro=true";
import { default as indexUsJ9Sal3cTMeta } from "/vercel/path0/web/pages/areas/index.vue?macro=true";
import { default as _91slug_937Tz4qJICVzMeta } from "/vercel/path0/web/pages/articles/[slug].vue?macro=true";
import { default as editBpCDA5NnDkMeta } from "/vercel/path0/web/pages/articles/edit.vue?macro=true";
import { default as indexQtIGAZ16HgMeta } from "/vercel/path0/web/pages/articles/index.vue?macro=true";
import { default as newxWYZiLcisMMeta } from "/vercel/path0/web/pages/articles/new.vue?macro=true";
import { default as authenticate_45app6Veyl9qewbMeta } from "/vercel/path0/web/pages/authenticate-app.vue?macro=true";
import { default as editDqAsoZulI8Meta } from "/vercel/path0/web/pages/courses/[courseId]/edit.vue?macro=true";
import { default as indexos0vIsGzhbMeta } from "/vercel/path0/web/pages/courses/[courseId]/index.vue?macro=true";
import { default as LoggedInIndexJYMIzElVFVMeta } from "/vercel/path0/web/pages/courses/[courseId]/LoggedInIndex.vue?macro=true";
import { default as LoggedOutIndex2lHB6vVosjMeta } from "/vercel/path0/web/pages/courses/[courseId]/LoggedOutIndex.vue?macro=true";
import { default as previewPCevnetqdxMeta } from "/vercel/path0/web/pages/courses/[courseId]/preview.vue?macro=true";
import { default as runDT6Ya0SZzZMeta } from "/vercel/path0/web/pages/courses/[courseId]/run.vue?macro=true";
import { default as create0OIsbOkZu5Meta } from "/vercel/path0/web/pages/courses/create.vue?macro=true";
import { default as debug_45mapsid7aRh3Ym5Meta } from "/vercel/path0/web/pages/debug-maps.vue?macro=true";
import { default as coursesdnOySbgHo9Meta } from "/vercel/path0/web/pages/explore/courses.vue?macro=true";
import { default as indexEnecjc5U7LMeta } from "/vercel/path0/web/pages/explore/index.vue?macro=true";
import { default as indexjqPwd0e7eHMeta } from "/vercel/path0/web/pages/index.vue?macro=true";
import { default as loginzz3zAXJZnbMeta } from "/vercel/path0/web/pages/login.vue?macro=true";
import { default as privacyTancXnnePpMeta } from "/vercel/path0/web/pages/privacy.vue?macro=true";
import { default as checkout7mNSwKKE0oMeta } from "/vercel/path0/web/pages/pro/checkout.vue?macro=true";
import { default as analyzeudDP1RE8keMeta } from "/vercel/path0/web/pages/runs/[runId]/analyze.vue?macro=true";
import { default as indexhdf1FNgDNfMeta } from "/vercel/path0/web/pages/runs/[runId]/index.vue?macro=true";
import { default as supportLG7pJ0waS8Meta } from "/vercel/path0/web/pages/support.vue?macro=true";
import { default as termsXy0y1ahYNqMeta } from "/vercel/path0/web/pages/terms.vue?macro=true";
import { default as user_45settings_45playgroundp8qOs6qty9Meta } from "/vercel/path0/web/pages/user-settings-playground.vue?macro=true";
import { default as coursesDpPbHusfM6Meta } from "/vercel/path0/web/pages/users/[userId]/courses.vue?macro=true";
import { default as indexAaeAPqJ4dsMeta } from "/vercel/path0/web/pages/users/[userId]/index.vue?macro=true";
export default [
  {
    name: "about-explore-courses",
    path: "/about/explore-courses",
    meta: explore_45courses3T2XhVpsZoMeta || {},
    component: () => import("/vercel/path0/web/pages/about/explore-courses.vue").then(m => m.default || m)
  },
  {
    name: "about",
    path: "/about",
    meta: index5WqLcFBFtGMeta || {},
    component: () => import("/vercel/path0/web/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "account-delete",
    path: "/account/delete",
    component: () => import("/vercel/path0/web/pages/account/delete.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/vercel/path0/web/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password",
    path: "/account/reset-password",
    component: () => import("/vercel/path0/web/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "admin-course-requests",
    path: "/admin/course-requests",
    meta: course_45requestsP9y2ESxpaBMeta || {},
    component: () => import("/vercel/path0/web/pages/admin/course-requests.vue").then(m => m.default || m)
  },
  {
    name: "areas-areaSlug",
    path: "/areas/:areaSlug()",
    meta: _91areaSlug_935RHbCZS09xMeta || {},
    component: () => import("/vercel/path0/web/pages/areas/[areaSlug].vue").then(m => m.default || m)
  },
  {
    name: "areas",
    path: "/areas",
    meta: indexUsJ9Sal3cTMeta || {},
    component: () => import("/vercel/path0/web/pages/areas/index.vue").then(m => m.default || m)
  },
  {
    name: "articles-slug",
    path: "/articles/:slug()",
    meta: _91slug_937Tz4qJICVzMeta || {},
    component: () => import("/vercel/path0/web/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: "articles-edit",
    path: "/articles/edit",
    meta: editBpCDA5NnDkMeta || {},
    component: () => import("/vercel/path0/web/pages/articles/edit.vue").then(m => m.default || m)
  },
  {
    name: "articles",
    path: "/articles",
    meta: indexQtIGAZ16HgMeta || {},
    component: () => import("/vercel/path0/web/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "articles-new",
    path: "/articles/new",
    component: () => import("/vercel/path0/web/pages/articles/new.vue").then(m => m.default || m)
  },
  {
    name: "authenticate-app",
    path: "/authenticate-app",
    meta: authenticate_45app6Veyl9qewbMeta || {},
    component: () => import("/vercel/path0/web/pages/authenticate-app.vue").then(m => m.default || m)
  },
  {
    name: "courses-courseId-edit",
    path: "/courses/:courseId()/edit",
    meta: editDqAsoZulI8Meta || {},
    component: () => import("/vercel/path0/web/pages/courses/[courseId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "courses-courseId",
    path: "/courses/:courseId()",
    meta: indexos0vIsGzhbMeta || {},
    component: () => import("/vercel/path0/web/pages/courses/[courseId]/index.vue").then(m => m.default || m)
  },
  {
    name: "courses-courseId-LoggedInIndex",
    path: "/courses/:courseId()/LoggedInIndex",
    component: () => import("/vercel/path0/web/pages/courses/[courseId]/LoggedInIndex.vue").then(m => m.default || m)
  },
  {
    name: "courses-courseId-LoggedOutIndex",
    path: "/courses/:courseId()/LoggedOutIndex",
    component: () => import("/vercel/path0/web/pages/courses/[courseId]/LoggedOutIndex.vue").then(m => m.default || m)
  },
  {
    name: "courses-courseId-preview",
    path: "/courses/:courseId()/preview",
    component: () => import("/vercel/path0/web/pages/courses/[courseId]/preview.vue").then(m => m.default || m)
  },
  {
    name: "courses-courseId-run",
    path: "/courses/:courseId()/run",
    meta: runDT6Ya0SZzZMeta || {},
    component: () => import("/vercel/path0/web/pages/courses/[courseId]/run.vue").then(m => m.default || m)
  },
  {
    name: "courses-create",
    path: "/courses/create",
    component: () => import("/vercel/path0/web/pages/courses/create.vue").then(m => m.default || m)
  },
  {
    name: "debug-maps",
    path: "/debug-maps",
    component: () => import("/vercel/path0/web/pages/debug-maps.vue").then(m => m.default || m)
  },
  {
    name: "explore-courses",
    path: "/explore/courses",
    component: () => import("/vercel/path0/web/pages/explore/courses.vue").then(m => m.default || m)
  },
  {
    name: "explore",
    path: "/explore",
    meta: indexEnecjc5U7LMeta || {},
    component: () => import("/vercel/path0/web/pages/explore/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexjqPwd0e7eHMeta || {},
    component: () => import("/vercel/path0/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginzz3zAXJZnbMeta || {},
    component: () => import("/vercel/path0/web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyTancXnnePpMeta || {},
    component: () => import("/vercel/path0/web/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "pro-checkout",
    path: "/pro/checkout",
    component: () => import("/vercel/path0/web/pages/pro/checkout.vue").then(m => m.default || m)
  },
  {
    name: "runs-runId-analyze",
    path: "/runs/:runId()/analyze",
    meta: analyzeudDP1RE8keMeta || {},
    component: () => import("/vercel/path0/web/pages/runs/[runId]/analyze.vue").then(m => m.default || m)
  },
  {
    name: "runs-runId",
    path: "/runs/:runId()",
    component: () => import("/vercel/path0/web/pages/runs/[runId]/index.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    meta: supportLG7pJ0waS8Meta || {},
    component: () => import("/vercel/path0/web/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsXy0y1ahYNqMeta || {},
    component: () => import("/vercel/path0/web/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-playground",
    path: "/user-settings-playground",
    component: () => import("/vercel/path0/web/pages/user-settings-playground.vue").then(m => m.default || m)
  },
  {
    name: "users-userId-courses",
    path: "/users/:userId()/courses",
    component: () => import("/vercel/path0/web/pages/users/[userId]/courses.vue").then(m => m.default || m)
  },
  {
    name: "users-userId",
    path: "/users/:userId()",
    component: () => import("/vercel/path0/web/pages/users/[userId]/index.vue").then(m => m.default || m)
  }
]